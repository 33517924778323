<template>
    <div class="slaStaffMyProfile">
  
      <!-- Menu Icon for small screens -->
    <div class="menu-icon" @click="toggleSidebar">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <!-- Sidebar Toggle Button for Smaller Screens -->
      <button v-if="isSmallScreen" class="show-sidebar-btn-small" @click="toggleSidebar"></button>

      <!-- Sidebar -->
      <div class="sidebar" :class="{ 'collapsed': sidebarCollapsed }">
        <div class="logo">Secondlifeasia Staff</div>
        <button class="sidebar-button" @click="onDashBoardClick">Dashboard</button>
        <button class="sidebar-button" @click="onCalendarClick">Calendar</button>
        <button class="sidebar-button" @click="onMyLeavesClick">My Leaves</button>
        <button class="sidebar-button" @click="onCodeOfConductClick">Code Of Conduct</button>
        <button class="sidebar-button" @click="onMyProfileClick">My Profile</button>
        <!-- Styled the logout button as well -->
        <button v-b-toggle.sidebar-1 class="sidebar-button logout-btn" @click="onLogoutClick">Logout</button>
      </div>
  
      <!-- Main Content -->
      <div class="main-content">
  
        <!-- Control Panel -->
        
        <div class="control-tile" style="width: 800px;">
            <h4 class="gap"><b>My Profile</b></h4><br><br>

            <div class="margin-fields gap">
                <label for="name">Name:</label>
                <span>{{ staffProfileData.username}}</span>
            </div>
            <div class="margin-fields gap">
                <label for="ic">IC:</label>
                <span>{{ staffProfileData.user_nric}}</span>
            </div>
            <div class="margin-fields gap">
                <label for="contact">Contact:</label>
                <span>{{ staffProfileData.contact }}</span>
            </div>
            <div class="margin-fields gap">
                <label for="address">Address:</label>
                <span>{{ staffProfileData.address }}</span>
            </div>
            <div class="margin-fields gap">
                <label for="company">Company:</label>
                <span>{{ staffProfileData.company }}</span>
            </div>
            <div class="margin-fields gap">
                <label for="department">Department:</label>
                <span>{{ staffProfileData.department }}</span>
            </div>
            <div class="margin-fields gap">
                <label for="position">Position:</label>
                <span>{{ staffProfileData.position }}</span>
            </div>
            <div class="margin-fields gap">
                <label for="emergency_contact">Emergency Contact:</label>
                <span>{{ staffProfileData.emergency_contact }}</span>
            </div>
            <br>
            <div class="margin-fields gap">
                <label for="email">Email:</label>
                <span>{{ staffProfileData.email }}</span>
            </div>
            <div class="margin-fields gap">
                <label for="password">Password:</label>
                <span>{{ staffProfileData.password }}</span>
            </div>

            <br>
            <div class="button-container">
              <button class="buttons button-edit-profile" @click="onEditProfileClick">Edit Profile</button>
            </div>
            
        </div>
  

  
      </div>
    </div>
  
</template>
  
  <script>
  import { defineComponent } from "vue";
  import { requestApply, getBalanceList, getRequestList, LeaveSettings, HolidayTotal, MyProfile } from '../api';
  
  export default defineComponent({
    name: "GgStaffDb",
    data() {
      return {
        sidebarCollapsed: false,
        isSmallScreen: window.innerWidth <= 768,
        attributes: [
          // Add more attributes as needed
        ],
        leaveType: 'Medical',
        startDate: '',
        endDate: '',
        reason: '',
        staffData: [],
        staffProfileData: {},
        balanceData: [],
        leaveTypes: [
          { value: 'ML', label: 'Medical Leave' },
          //{ value: 'Emergency', label: 'Emergency Leave' },
          { value: 'AL', label: 'Annual Leave' },
          // Add more leave types as needed
        ],
        isProcessing: false, // New property to indicate if the request is being processed
      };
    },
  
    methods: {
      onDashBoardClick() {
        this.$router.push("/slastaffdb");
      },
      onCalendarClick() {
        this.$router.push("/slaStaffCalendar");
      },
      onMyLeavesClick() {
        // Replace with your actual route for My Leaves
        this.$router.push("/slaStaffMyLeaves");
      },
      onCodeOfConductClick() {
              const externalLink = "https://dpp-object-space.sgp1.digitaloceanspaces.com/res/Employee%20Handbook%202023.12.pdf";
              window.open(externalLink, '_blank');
          },
      onMyProfileClick(){
        this.$router.push("/slaStaffMyProfile");
      },
      onPendingLeavesClick() {
        // Handle pending leaves click
      },
      onLeaveLogsClick() {
        // Handle leave logs click
      },
      onBalanceClick() {
        // Handle balance click
      },
      onLogoutClick() {
        this.$router.push("/");
      },
      onEditProfileClick(){
        this.$router.push("/slaStaffEditProfile")
      },
      toggleSidebar() {
        this.sidebarCollapsed = !this.sidebarCollapsed;
      },
  
      handleResize() {
        this.isSmallScreen = window.innerWidth <= 768;
      },
  
      async banceList() {
        try {
          const formData = localStorage.sessionid
  
          const response = await getBalanceList(formData);
  
          console.log('Leave Request Submitted:', response);
          this.balanceData = response.data
  
        } catch (error) {
          console.error('Leave Request Error:', error);
          // Handle leave request error (e.g., show error message to the user)
        }
  
      },
      async myLeavs() {
        try {
          const formData = localStorage.sessionid
  
          const response = await getRequestList(formData);
  
          console.log('Leave Request Submitted:', response);
          this.staffData = response.data
  
        } catch (error) {
          console.error('Leave Request Error:', error);
          // Handle leave request error (e.g., show error message to the user)
        }
      },

      async myProf() {
      try {
        const session_id = localStorage.sessionid

        const response = await MyProfile(session_id);

        console.log('Profile Requested Successfully:', response);
        this.staffProfileData = response

      } catch (error) {
        console.error('Profile Request Error:', error);
        // Handle profile request error (e.g., show error message to the user)
      }
    },
  
      async submitRequest() {
        try {
          // Set isProcessing to true to show the spinner
          this.isProcessing = true;
  
          const formData = {
            session_id: localStorage.sessionid,
            leave_name: this.leaveType,
            start_date: this.startDate,
            end_date: this.endDate,
            reason: this.reason,
          };
  
          const response = await requestApply(formData);
  
          console.log('Leave Request Submitted:', response);
  
          // Optionally, handle success and update the UI or navigate to another page
          // ...
  
          // Reset the form after submission (optional)
          this.resetForm();
          this.myLeavs();
        } catch (error) {
          console.error('Leave Request Error:', error);
          // Handle leave request error (e.g., show error message to the user)
        } finally {
          // Reset isProcessing when the request is completed (whether successful or not)
          this.isProcessing = false;
        }
      },
      async leavebalance() {
        try {
          const formData = localStorage.sessionid
  
          const response = await LeaveSettings(formData);
  
          console.log('Leave Request Submitted:', response);
          this.leaveTypes = response.data.l_options
          //this.pendingLeavesData = response.data
          //this.leaveLogsData = response.data
  
        } catch (error) {
          console.error('Leave Request Error:', error);
          // Handle leave request error (e.g., show error message to the user)
        }
      },
  
      resetForm() {
        this.leaveType = 'Medical';
        this.startDate = '';
        this.endDate = '';
        this.reason = '';
      },
      async holidayTotal() {
        var v = await HolidayTotal(localStorage.sessionid)
        console.log(v.data)
        var itemss = this.attributes.concat()
        var indexes = 0
        for (const item of v.data) {
          // Manipulate or perform operations on each item as needed
          // For example, you can modify properties or add new ones
          var itemsss = {
            key: item.Key,
            customData: {
              "description": item.Name,
              "description2": item.Reason
            },
            dates: [
              {
                "start": item.Start_Date,
                "end": item.End_Date
              }
            ],
            dot: {
              color: item.Color,
              fillMode: 'solid',
            },
            popover: true,
            order: indexes,
            hover: {
              backgroundColor: 'lightblue',
            },
          }
          itemss.push(itemsss)
          indexes++;
          // Add the modified item to the loopedArray
          //this.loopedArray.push(item);
        }
        console.log(itemss)
  
        this.attributes = itemss
        console.log(this.attributes)
        //this.holidayData = v.data
      },
    },
  
    mounted() {
      window.addEventListener("resize", this.handleResize);
/*       this.banceList()
      this.myLeavs()
      this.leavebalance()
      this.holidayTotal() */
      this.myProf()
    },
  
    beforeUnmount() {
      window.removeEventListener("resize", this.handleResize);
    },
  });
  </script>
  
  
  <style scoped lang="scss">
    @import '../styles/styles.scss';

    .input-row {
      display: grid;
      grid-template-columns: 100px auto; /* Adjust the width of the label column as needed */
      width: calc(100% - 600px); /* Adjust the width as needed */
      overflow-wrap: break-word;

}
  </style>
  