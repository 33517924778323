<template>
    <div class="slaStaffMyProfile">
  
      <!-- Menu Icon for small screens -->
      <div class="menu-icon" @click="toggleSidebar">
        <span></span>
        <span></span>
        <span></span>
      </div>
  
      <!-- Sidebar Toggle Button for Smaller Screens -->
      <button v-if="isSmallScreen" class="show-sidebar-btn-small" @click="toggleSidebar"></button>
  
      <!-- Sidebar -->
      <div class="sidebar" :class="{ 'collapsed': sidebarCollapsed }">
        <div class="logo">Secondlifeasia Staff</div>
        <button class="sidebar-button" @click="onDashBoardClick">Dashboard</button>
        <button class="sidebar-button" @click="onCalendarClick">Calendar</button>
        <button class="sidebar-button" @click="onMyLeavesClick">My Leaves</button>
        <button class="sidebar-button" @click="onCodeOfConductClick">Code Of Conduct</button>
        <button class="sidebar-button" @click="onMyProfileClick">My Profile</button>
        <!-- Styled the logout button as well -->
        <button v-b-toggle.sidebar-1 class="sidebar-button logout-btn" @click="onLogoutClick">Logout</button>
      </div>
  
      <!-- Main Content -->
      <div class="main-content">
  
        <!-- Control Panel -->
        
        <div class="control-tile" style="width: 800px;" >
          <h4><b>Edit Profile</b></h4><br>
          <div class="margin-fields gap">
            <label for="name">Name:</label>
            <input type="text" :placeholder="staffProfileData?.username" v-model="editedProfile.username">
          </div>
          
          <div class="margin-fields gap">
            <label for="contact">Contact:</label>
            <input type="text" :placeholder="staffProfileData?.contact" v-model="editedProfile.contact">
          </div>
          <div class="margin-fields gap">
            <label for="address">Address:</label>
            <textarea :placeholder="staffProfileData?.address" v-model="editedProfile.address"></textarea>
          </div>
          
          <div class="margin-fields gap">
            <label for="emergency_contact">Emergency Contact:</label>
            <input type="text" :placeholder="staffProfileData?.emergency_contact" v-model="editedProfile.emergency_contact">
          </div>

          <br>
          
          <div class="margin-fields gap">
            <label for="password">Password:</label>
            <input type="text" :placeholder="staffProfileData?.password" v-model="editedProfile.password">
          </div>
          <br>
          <!-- Add more fields for editing profile info as needed -->
          <div class="button-container" >
            <button class="buttons button-edit-profile cancel" @click="cancelEdit">Cancel</button>
            <button class="buttons button-edit-profile submit" @click="submitEdit">Submit</button>
          </div>

        </div>

      </div>

    </div>
  
</template>
  
  <script>
  import { defineComponent } from "vue";
  import { requestApply, getBalanceList, getRequestList, LeaveSettings, HolidayTotal, MyProfile, EditMyProfile } from '../api';
  
  export default defineComponent({
  name: 'slaStaffEditProfile',
  data() {
    return {
      staffProfileData: {},
      editedProfile: {}
    }
  },
  computed: {
    updatedEditedProfile() {
      return {
        username: this.editedProfile.username || this.staffProfileData.username,
        password: this.editedProfile.password || this.staffProfileData.password,
        address: this.editedProfile.address || this.staffProfileData.address,
        contact: this.editedProfile.contact || this.staffProfileData.contact,
        emergency_contact: this.editedProfile.emergency_contact || this.staffProfileData.emergency_contact,

        user_nric: this.staffProfileData.user_nric,
        company: this.staffProfileData.company,
        department: this.staffProfileData.department,
        position: this.staffProfileData.position,
        email: this.staffProfileData.email,
        // Add more profile fields as needed
      };
    },
  },
  
    methods: {

      cancelEdit() {
        // Reset editedProfile to original profile data or fetch from API
        this.$router.push("/slaStaffMyProfile"); // Redirect to profile page
      },
      async submitEdit() {
        // Perform API call or submit edited profile data
        EditMyProfile(localStorage.sessionid, this.updatedEditedProfile)
        await new Promise(resolve => setTimeout(resolve, 500));
        // After successful submission, navigate to profile page
        this.$router.push("/slaStaffMyProfile"); // Redirect to profile page
      },

      onDashBoardClick() {
        this.$router.push("/slastaffdb");
      },
      onCalendarClick() {
        this.$router.push("/slaStaffCalendar");
      },
      onMyLeavesClick() {
        // Replace with your actual route for My Leaves
        this.$router.push("/slaStaffMyLeaves");
      },
      onCodeOfConductClick() {
              const externalLink = "https://dpp-object-space.sgp1.digitaloceanspaces.com/res/Employee%20Handbook%202023.12.pdf";
              window.open(externalLink, '_blank');
          },
      onMyProfileClick(){
        this.$router.push("/slaStaffMyProfile");
      },
      onPendingLeavesClick() {
        // Handle pending leaves click
      },
      onLeaveLogsClick() {
        // Handle leave logs click
      },
      onBalanceClick() {
        // Handle balance click
      },
      onLogoutClick() {
        this.$router.push("/");
      },
      onEditProfileClick(){

      },
      toggleSidebar() {
        this.sidebarCollapsed = !this.sidebarCollapsed;
      },
  
      handleResize() {
        this.isSmallScreen = window.innerWidth <= 768;
      },
  
      async banceList() {
        try {
          const formData = localStorage.sessionid
  
          const response = await getBalanceList(formData);
  
          console.log('Leave Request Submitted:', response);
          this.balanceData = response.data
  
        } catch (error) {
          console.error('Leave Request Error:', error);
          // Handle leave request error (e.g., show error message to the user)
        }
  
      },
      async myLeavs() {
        try {
          const formData = localStorage.sessionid
  
          const response = await getRequestList(formData);
  
          console.log('Leave Request Submitted:', response);
          this.staffData = response.data
  
        } catch (error) {
          console.error('Leave Request Error:', error);
          // Handle leave request error (e.g., show error message to the user)
        }
      },

      async myProf() {
      try {
        const session_id = localStorage.sessionid

        const response = await MyProfile(session_id);

        console.log('Profile Requested Successfully:', response);
        this.staffProfileData = response

      } catch (error) {
        console.error('Profile Request Error:', error);
        // Handle profile request error (e.g., show error message to the user)
      }
    },
  
      async submitRequest() {
        try {
          // Set isProcessing to true to show the spinner
          this.isProcessing = true;
  
          const formData = {
            session_id: localStorage.sessionid,
            leave_name: this.leaveType,
            start_date: this.startDate,
            end_date: this.endDate,
            reason: this.reason,
          };
  
          const response = await requestApply(formData);
  
          console.log('Leave Request Submitted:', response);
  
          // Optionally, handle success and update the UI or navigate to another page
          // ...
  
          // Reset the form after submission (optional)
          this.resetForm();
          this.myLeavs();
        } catch (error) {
          console.error('Leave Request Error:', error);
          // Handle leave request error (e.g., show error message to the user)
        } finally {
          // Reset isProcessing when the request is completed (whether successful or not)
          this.isProcessing = false;
        }
      },
      async leavebalance() {
        try {
          const formData = localStorage.sessionid
  
          const response = await LeaveSettings(formData);
  
          console.log('Leave Request Submitted:', response);
          this.leaveTypes = response.data.l_options
          //this.pendingLeavesData = response.data
          //this.leaveLogsData = response.data
  
        } catch (error) {
          console.error('Leave Request Error:', error);
          // Handle leave request error (e.g., show error message to the user)
        }
      },
  
      resetForm() {
        this.leaveType = 'Medical';
        this.startDate = '';
        this.endDate = '';
        this.reason = '';
      },
      async holidayTotal() {
        var v = await HolidayTotal(localStorage.sessionid)
        console.log(v.data)
        var itemss = this.attributes.concat()
        var indexes = 0
        for (const item of v.data) {
          // Manipulate or perform operations on each item as needed
          // For example, you can modify properties or add new ones
          var itemsss = {
            key: item.Key,
            customData: {
              "description": item.Name,
              "description2": item.Reason
            },
            dates: [
              {
                "start": item.Start_Date,
                "end": item.End_Date
              }
            ],
            dot: {
              color: item.Color,
              fillMode: 'solid',
            },
            popover: true,
            order: indexes,
            hover: {
              backgroundColor: 'lightblue',
            },
          }
          itemss.push(itemsss)
          indexes++;
          // Add the modified item to the loopedArray
          //this.loopedArray.push(item);
        }
        console.log(itemss)
  
        this.attributes = itemss
        console.log(this.attributes)
        //this.holidayData = v.data
      },
    },
  
    mounted() {
      window.addEventListener("resize", this.handleResize);
/*    this.banceList()
      this.myLeavs()
      this.leavebalance()
      this.holidayTotal() */
      this.myProf()
    },
  
    beforeUnmount() {
      window.removeEventListener("resize", this.handleResize);
    },
  });
  </script>
  
  
  <style scoped lang="scss">
  
    @import '../styles/styles.scss';

    .cancel{
      background: rgb(182, 43, 43)
    }

    .submit{
      background: rgb(93, 156, 93)
    }
  </style>
  