<template>
  <div :class="[$style.loginContainer, { [$style.blurredBackground]: isBackgroundBlurred }]">
    <div :class="$style.loginForm">
      <div :class="$style.logo">Secondlifeasia Admin</div>
      <div :class="$style.inputGroup">
        <input v-model="email" type="text" placeholder="Enter your e-mail" 
        :class="$style.inputField" />
      </div>
      <div :class="$style.inputGroup">
        <input v-model="password" type="password" placeholder="Password" 
        :class="$style.inputField" />
      </div>
      <button @click="onLoginClick" 
      :class="$style.loginBtn">Login</button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { signIn } from '../api';

export default defineComponent({
  name: "SlaAdminLogin",
  data() {
    return {
      email: "",
      password: "",
      isBackgroundBlurred: false,
    };
  },
  methods: {
    async onLoginClick() {
      try {
        const response = await signIn(this.email, this.password, "secondlifeasia", "admin");
        console.log('Sign In Response:', response);
        // Handle successful login (e.g., store user data in Vuex store or local storage)
        //this.$router.push("/slaadmindb");
        if (response.result === "login success"){
          localStorage.sessionid = response["session_id"]
          localStorage.role = response["role"]
          this.$router.push("/slaadmindb");
        }
      } catch (error) {
        console.error('Sign In Error:', error);
        // Handle login error (e.g., show error message to the user)
      }
    },
  },
});
</script>


<style module>
  
  .loginContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #013042;
    overflow: hidden;
    text-align: center;

    font-size: var(--font-size-5xl);
    font-size: 30px;

    color: #000;
    font-family: var(--font-century-gothic);

    
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
  }

  .blurredBackground {
    /* Add your blur effect if needed */
    filter: blur(5px);
  }

  .loginForm {
    width: 300px;
    text-align: center;
    background: #013042;
  }

  .logo {
    margin-bottom: 20px;
    color: rgba(243, 244, 245, 0.9);
    text-align: center;
    font-family: Century Gothic;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .inputGroup {
    margin-bottom: 15px;
  }

  .inputField {
    width: 100%;
    padding: 12px;
    border: 2px solid var(--color-darkolivegreen);
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 18px;
    color: var(--color-darkolivegreen);
    outline: none;
    transition: border-color 0.2s;
    border-bottom: 1px solid #000;
    background: #fae994;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  }

  .inputField:focus {
    border-color: var(--color-lightyellow-100);
  }

  .loginBtn {
    width: 50%;
    padding: 12px;
    background: #ffe358;
    color: var(--color-lightyellow-100);
    border: 2px solid #000;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    transition: background-color 0.3s;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);

    color: #11202fb0;
    text-align: center;
    font-style: normal;
    line-height: normal;
  }

  .loginBtn:hover {
    background-color: #dfc64d;
  }
</style>
