<template>
  <div class="ggStaffCalendar">
    <!-- Menu Icon for small screens -->
    <div class="menu-icon" @click="toggleSidebar">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <!-- Sidebar Toggle Button for Smaller Screens -->
    <button v-if="isSmallScreen" class="show-sidebar-btn-small" @click="toggleSidebar"></button>

    <!-- Sidebar -->
    <div class="sidebar" :class="{ 'collapsed': sidebarCollapsed }">
      <div class="logo">Guard Genius Staff</div>
      <button class="sidebar-button" @click="onDashBoardClick">Dashboard</button>
      <button class="sidebar-button" @click="onCalendarClick">Calendar</button>
      <button class="sidebar-button" @click="onMyLeavesClick">My Leaves</button>
      <!-- Styled the logout button as well -->
      <button v-b-toggle.sidebar-1 class="sidebar-button logout-btn" @click="onLogoutClick">Logout</button>
    </div>

    <!-- Main Content -->
    <div class="main-content">

      <!-- Calendar -->
      <div class="calendar">
        <VCalendar
          transparent
          borderless
          expanded
          title-position="right"
          is-dark="{selector: ':root', darkClass: 'dark'}"
          :rows="1"
          :attributes="attributes"
        >
        <template #day-popover = "{ attributes,order}">
            <div v-for="{ key, customData } in attributes" :key="key" class="text-xs text-gray-700 dark:text-gray-300">
              {{ order }}
              {{ customData.description }}
              {{ customData.description2 }}
            </div>
            <!--{{ attributes[0].customData.description }}-->
          </template>
        </VCalendar>

      </div>

      <!-- Dynamic Table for Holidays -->
      <div class="calendar holiday">
        <b>2024</b>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Company Holiday</th>
              <th scope="col">Date</th>
              <th scope="col">Day</th>
            </tr>
          </thead>
          <tbody>
            <!-- Loop through each item in holidayData and populate the table dynamically -->
            <tr v-for="(item, index) in holidayData" :key="index">
              <td>{{ item.Name }}</td>
              <td>{{ item.Date }}</td>
              <td>{{ item.Day }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { getHolidayList, HolidayTotal } from '@/api';

export default defineComponent({
  name: 'ggStaffCalendar',
  data() {
    return {
      sidebarCollapsed: false,
      isSmallScreen: window.innerWidth <= 768,
      attributes: [
        // Add more attributes as needed
      ],
      holidayData: [],
    };
  },

  methods: {
    onDashBoardClick() {
      this.$router.push('/ggStaffdb');
    },
    onCalendarClick() {
      this.$router.push('/ggStaffCalendar');
    },
    onMyLeavesClick() {
      // Replace with your actual route for Staff on Leave
      this.$router.push('/ggStaffMyLeaves');
    },
    onPendingLeavesClick() {
      // Handle pending leaves click
    },
    onLeaveLogsClick() {
      // Handle leave logs click
    },
    onBalanceClick() {
      // Handle balance click
    },
    onLogoutClick() {
      // Handle logout
      this.$router.push('/');
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },

    handleResize() {
      this.isSmallScreen = window.innerWidth <= 768;
    },

    async holidayCalender () {
    var v = await getHolidayList(localStorage.sessionid)
    console.log(v.data)
    this.holidayData = v.data
    },
    async holidayTotal () {
      var v = await HolidayTotal(localStorage.sessionid)
      console.log(v.data)
      var itemss = this.attributes.concat()
      var indexes = 0
      for (const item of v.data) {
        // Manipulate or perform operations on each item as needed
        // For example, you can modify properties or add new ones
        var itemsss = {
          key:item.Key,
          customData:{
            "description":item.Name,
            "description2":item.Reason
          },
          dates:[
            {
              "start":item.Start_Date,
              "end":item.End_Date
            }
          ],
          dot: {
              color: item.Color,
              fillMode: 'solid',
            },
          popover: true,
          order: indexes,
          hover: {
            backgroundColor: 'lightblue',
          },
        }
        itemss.push(itemsss)
        indexes++;
        // Add the modified item to the loopedArray
        //this.loopedArray.push(item);
      }
      console.log(itemss)
      
      this.attributes = itemss
      console.log(this.attributes)
      //this.holidayData = v.data
    },
  },

  mounted() {
    // Add an event listener to handle window resize
    window.addEventListener('resize', this.handleResize);
    this.holidayCalender()
    this.holidayTotal()
  },

  beforeUnmount() {
    // Remove the event listener to avoid memory leaks
    window.removeEventListener('resize', this.handleResize);
  },
});
</script>

<style scoped>
/* Add your styles here */
.ggStaffCalendar {
  display: flex;
  overflow: hidden;
  /* Hide the sidebar when collapsed */
}

.menu-icon {
  display: none;
  /* Initially hide the menu icon for larger screens */
  cursor: pointer;
}

.menu-icon span {
  display: block;
  height: 3px;
  width: 25px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

.sidebar {
  width: 250px;
  background-color: #000;
  color: #000;
  padding: 20px;
  padding-right: 0%;
  /* Set sidebar height to full viewport height */
  display: flex;
  flex-direction: column;
  transition: 0.4s;
  /* Add transition for smoother animation */
  background: #1e1e0eb2;
}

.title {
  padding-bottom: 20px;
  padding-top: 20px;
  color: rgba(0, 0, 0, 0.40);
  text-align: center;
  font-family: Century Gothic;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sidebar.collapsed {
  width: 0;
  overflow: hidden;
}

.logo {
  font-size: 20px;
  margin-bottom: 20px;
  color: #fbfadc85;
  text-align: center;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-right: 25px;
}

.sidebar-button {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #555;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  border-radius: 10px 0px 0px 10px;
  background: #000;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 100;
  color: #fbfadca4;
  line-height: normal;
  text-align: left;
  text-indent: 20px;
}

.sidebar-button.logout-btn {
  /* Style for logout button */
  cursor: pointer;
  margin-top: auto;
  /* Push logout button to the bottom */
  border-radius: 10px;
  background: #000;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  width: 100px;
  flex-shrink: 0;
  font-family: Century Gothic;
  color: #fbfadca4;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
  text-indent: 13px;
}

/* Button to bring sidebar back in for smaller screens */
.show-sidebar-btn-small {
  position: fixed;
  top: 10px;
  left: 5px;
  width: 25px;
  height: 20px;
  z-index: 1000;
  cursor: pointer;
  display: block;
  background-color: rgba(0, 0, 0, 0.30);
  border: 0px;
}

/* Button to bring sidebar back in for larger screens */
.show-sidebar-btn {
  position: fixed;
  top: 20px;
  left: 10px;
  z-index: 1000;
  cursor: pointer;
  display: block;
}

.main-content {
  flex: 1;
  padding: 20px;
  background: #FBFADC;
  backdrop-filter: blur(5px);
  overflow-y: auto;
  height: 100vh;
}

.control-panel {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.control-tile {
  flex: 1;
  padding: 70px;
  /* here is where it changes once i start edditing tiles */
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.30);
}

.calendar {
  flex: 1;
  padding: 25px;
  /* here is where it changes once i start edditing tiles */
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.30);
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .main-content {
    padding: 6px;
  }

  .sidebar {
    width: 0;
    overflow: hidden;
    padding: 0%;
  }

  .menu-icon {
    display: none;
  }

  /* Hide the show-sidebar-btn on small screens when sidebar is visible */
  .show-sidebar-btn {
    display: none;
    color: #00000060;
  }

  .calendar {
    flex: 1;
    padding: 25px;
    /* here is where it changes once i start edditing tiles */
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.30);
  }

  .calendar.holiday {
    font-size: 14px;
    padding: 8px 2px;
  }

  .calendar.holiday tr td:nth-child(2) {
    font-size: 12px;
    padding: 3px;
    vertical-align: middle;
  }
}

.vc-blue {
  --vc-accent-50: #f9fbf2;
  --vc-accent-100: #f4f7e6;
  --vc-accent-200: #eaf0c1;
  --vc-accent-300: #dce896;
  --vc-accent-400: #cdda65;
  --vc-accent-500: #fad704; /* Updated base color here */
  --vc-accent-600: #e3b702;
  --vc-accent-700: #c69803;
  --vc-accent-800: #a97f04;
  --vc-accent-900: #8e6705;
}
</style>
