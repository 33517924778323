import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
//import { IconsPlugin } from 'bootstrap-vue';
//Import BootstrapVue styles
//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap-vue/dist/bootstrap-vue.css';


//import './router/app.scss';
import App from "./App.vue";

import welcomePage from "./pages/welcomePage.vue";
import slaAdminLogin from "./pages/slaAdminLogin.vue";
import ggAdminLogin from "./pages/ggAdminLogin.vue";
import slaStaffLogin from "./pages/slaStaffLogin.vue";
import ggStaffLogin from "./pages/ggStaffLogin.vue";
import ggStaffCalendar from "./pages/ggStaffCalendar.vue";
import ggStaffMyleaves from "./pages/ggStaffMyleaves.vue";
import ggAdminAddstaff from "./pages/ggAdminAddstaff.vue";
import ggAdminStaff from "./pages/ggAdminStaff.vue";
import ggAdminCalendar from "./pages/ggAdminCalendar.vue";
import ggAdminLeavestoapprove from "./pages/ggAdminLeavestoapprove.vue";
import ggAdminMyleaves from "./pages/ggAdminMyleaves.vue";
import ggStaffDb from "./pages/ggStaffDb.vue";
import ggAdminDb from "./pages/ggAdminDb.vue";
import slaStaffCalendar from "./pages/slaStaffCalendar.vue";
import slaStaffMyleaves from "./pages/slaStaffMyleaves.vue";
import slaAdminAddstaff from "./pages/slaAdminAddstaff.vue";
import slaAdminStaff from "./pages/slaAdminStaff.vue";
import slaAdminCalendar from "./pages/slaAdminCalendar.vue";
import slaAdminLeavestoApprove from "./pages/slaAdminLeavestoApprove.vue";
import slaAdminMyleaves from "./pages/slaAdminMyleaves.vue";
import slaStaffDb from "./pages/slaStaffDb.vue";
import slaAdminDb from "./pages/slaAdminDb.vue";
import slaAdminLeaveApply from "./pages/slaAdminLeaveApply.vue";
import codeOfConduct from "./pages/codeOfConduct.vue";
import slaStaffMyProfile from "./pages/slaStaffMyProfile.vue"
import slaStaffEditProfile from "./pages/slaStaffEditProfile.vue"

import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import "./global.css";





const routes = [
  {
    path: "/",
    name: "welcomePage",
    component: welcomePage,
  },
  {
    path: "/slaadminlogin",
    name: "slaAdminLogin",
    component: slaAdminLogin,
  },
  {
    path: "/ggadminlogin",
    name: "ggAdminLogin",
    component: ggAdminLogin,
  },
  {
    path: "/slastafflogin",
    name: "slaStaffLogin",
    component: slaStaffLogin,
  },
  {
    path: "/ggstafflogin",
    name: "ggStaffLogin",
    component: ggStaffLogin,
  },
  {
    path: "/ggstaffcalendar",
    name: "ggStaffCalendar",
    component: ggStaffCalendar,
  },
  {
    path: "/ggstaffmyleaves",
    name: "ggStaffMyleaves",
    component: ggStaffMyleaves,
  },
  {
    path: "/ggadminaddstaff",
    name: "ggAdminAddstaff",
    component: ggAdminAddstaff,
  },
  {
    path: "/ggadminstaff",
    name: "ggAdminStaff",
    component: ggAdminStaff,
  },
  {
    path: "/ggadmincalendar",
    name: "ggAdminCalendar",
    component: ggAdminCalendar,
  },
  {
    path: "/ggadminleavestoapprove",
    name: "ggAdminLeavestoapprove",
    component: ggAdminLeavestoapprove,
  },
  {
    path: "/ggadminmyleaves",
    name: "ggAdminMyleaves",
    component: ggAdminMyleaves,
  },
  {
    path: "/ggstaffdb",
    name: "ggStaffDb",
    component: ggStaffDb,
  },
  {
    path: "/ggadmindb",
    name: "ggAdminDb",
    component: ggAdminDb,
  },
  {
    path: "/slastaffcalendar",
    name: "slaStaffCalendar",
    component: slaStaffCalendar,
  },
  {
    path: "/slastaffmyleaves",
    name: "slaStaffMyleaves",
    component: slaStaffMyleaves,
  },
  {
    path: "/slaadminaddstaff",
    name: "slaAdminAddstaff",
    component: slaAdminAddstaff,
  },
  {
    path: "/slaadminstaff",
    name: "slaAdminStaff",
    component: slaAdminStaff,
  },
  {
    path: "/slaadmincalendar",
    name: "slaAdminCalendar",
    component: slaAdminCalendar,
  },
  {
    path: "/slaadminleavestoapprove",
    name: "slaAdminLeavestoApprove",
    component: slaAdminLeavestoApprove,
  },
  {
    path: "/slaadminmyleaves",
    name: "slaAdminMyleaves",
    component: slaAdminMyleaves,
  },
  {
    path: "/slastaffdb",
    name: "slaStaffDb",
    component: slaStaffDb,
  },
  {
    path: "/slaadmindb",
    name: "slaAdminDb",
    component: slaAdminDb,
  },
  {
    path: "/slaadminleaveapply",
    name: "slaAdminLeaveApply",
    component: slaAdminLeaveApply,
  },
  {
    path: "/codeOfConduct",
    name: "CodeOfConduct",
    component: codeOfConduct,
  },
  {
    path: "/slaStaffMyProfile",
    name: "slaStaffMyProfile",
    component: slaStaffMyProfile,
  },
  {
    path: "/slaStaffEditProfile",
    name: "slaStaffEditProfile",
    component: slaStaffEditProfile,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
  const documentTitle =
    toRoute?.meta && toRoute?.meta?.title
      ? toRoute?.meta?.title
      : "HR-Leave-Management-";
  document.title = documentTitle;
  if (toRoute?.meta?.description) {
    addMetaTag(toRoute?.meta?.description);
  }
  next();
});

const addMetaTag = (value) => {
  let element = document.querySelector(`meta[name='description']`);

  if (element) {
    element.setAttribute("content", value);
  } else {
    element = document.createElement("meta");
    element.name = "description";
    element.content = value;
    document.head.appendChild(element);
  }
};

// Uncomment the following section
const app = createApp(App);
app.use(VCalendar, {})
// Use BootstrapVue
//app.use(BootstrapVue);
// Make BootstrapVue available throughout your project
//app.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
//app.use(IconsPlugin);

app.use(router).mount("#app");

export default router;