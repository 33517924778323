<template>
  <div class="slaAdminStaff">
    <!-- Menu Icon for small screens -->
    <div class="menu-icon" @click="toggleSidebar">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <!-- Sidebar Toggle Button for Smaller Screens -->
    <button v-if="isSmallScreen" class="show-sidebar-btn-small" @click="toggleSidebar"></button>


    <!-- Sidebar -->
    <div class="sidebar" :class="{ 'blurred': showPopup }">
      <div class="logo">Secondlifeasia Admin</div>
      <button class="sidebar-button" @click="onDashBoardClick">Dashboard</button>
      <button class="sidebar-button" @click="onCalendarClick">Calendar</button>
      <button class="sidebar-button" @click="onMyLeavesClick">Staff on Leave</button>
      <button class="sidebar-button" @click="onLeavesToApproveClick">Leaves to Approve</button>
      <button class="sidebar-button" @click="onDashBoardClick2">Leave to Apply</button>
      <button class="sidebar-button" @click="onStaffClick">Staff Management</button>
      <button class="sidebar-button" @click="onCodeOfConductClick">Code Of Conduct</button>
      <!-- Styled the logout button as well -->
      <button v-b-toggle.sidebar-1 class="sidebar-button logout-btn" @click="onLogoutClick">Logout</button>
    </div>

    <!-- Popup view profile content -->
    <div class="full-page-popup" v-if="showPopup">
    <div class="popup-content">
      <!-- Your popup content goes here -->
      <div class="control-tile" style="width: 800px;">
            <h4 class="gap"><b>{{ staffProfileData.username }}'s Profile</b></h4><br><br>

            <div class="margin-fields gap">
                <label for="name">Name:</label>
                <span>{{ staffProfileData.username}}</span>
            </div>
            <div class="margin-fields gap">
                <label for="ic">IC:</label>
                <span>{{ staffProfileData.user_nric}}</span>
            </div>
            <div class="margin-fields gap">
                <label for="contact">Contact:</label>
                <span>{{ staffProfileData.contact }}</span>
            </div>
            <div class="margin-fields gap">
                <label for="address">Address:</label>
                <span>{{ staffProfileData.address }}</span>
            </div>
            <div class="margin-fields gap">
                <label for="company">Company:</label>
                <span>{{ staffProfileData.company }}</span>
            </div>
            <div class="margin-fields gap">
                <label for="department">Department:</label>
                <span>{{ staffProfileData.department }}</span>
            </div>
            <div class="margin-fields gap">
                <label for="position">Position:</label>
                <span>{{ staffProfileData.position }}</span>
            </div>
            <div class="margin-fields gap">
                <label for="emergency_contact">Emergency Contact:</label>
                <span>{{ staffProfileData.emergency_contact }}</span>
            </div>
            <br>
            <div class="margin-fields gap">
                <label for="email">Email:</label>
                <span>{{ staffProfileData.email }}</span>
            </div>
            <div class="margin-fields gap">
                <label for="password">Password:</label>
                <span>{{ staffProfileData.password }}</span>
            </div>

            <br>
            <div class="button-container">
              <button class="buttons button-edit-profile" @click="onEditProfileClick">Edit Profile</button>
            </div>
            <button @click="closePopup">Close Popup</button>
            
        </div>
      
    </div>
    </div>

    <!-- Popup edit profile content -->
    <div class="full-page-popup" v-if="showEditPopup">
    <div class="popup-content">
      <!-- Your popup content goes here -->
      <div class="control-tile" style="width: 800px;" >
          <h4><b>Edit Profile</b></h4><br>
          <div class="margin-fields gap">
            <label for="name">Name:</label>
            <input type="text" :placeholder="staffProfileData?.username" v-model="editedProfile.username">
          </div>

          <div class="margin-fields gap">
            <label for="ic">IC:</label>
            <input type="text" :placeholder="staffProfileData?.user_nric" v-model="editedProfile.user_nric">
          </div>
          
          <div class="margin-fields gap">
            <label for="contact">Contact:</label>
            <input type="text" :placeholder="staffProfileData?.contact" v-model="editedProfile.contact">
          </div>
          <div class="margin-fields gap">
            <label for="address">Address:</label>
            <textarea :placeholder="staffProfileData?.address" v-model="editedProfile.address"></textarea>
          </div>

          <div class="margin-fields gap">
            <label for="company">Company:</label>
            <input type="text" :placeholder="staffProfileData?.company" v-model="editedProfile.company">
          </div>

          <div class="margin-fields gap">
            <label for="department">Department:</label>
            <input type="text" :placeholder="staffProfileData?.department" v-model="editedProfile.department">
          </div>

          <div class="margin-fields gap">
            <label for="position">Position:</label>
            <input type="text" :placeholder="staffProfileData?.position" v-model="editedProfile.position">
          </div>
          
          <div class="margin-fields gap">
            <label for="emergency_contact">Emergency Contact:</label>
            <input type="text" :placeholder="staffProfileData?.emergency_contact" v-model="editedProfile.emergency_contact">
          </div>

          <br>

          <div class="margin-fields gap">
            <label for="email">Email:</label>
            <input type="text" :placeholder="staffProfileData?.email" v-model="editedProfile.email">
          </div>
          
          <div class="margin-fields gap">
            <label for="password">Password:</label>
            <input type="text" :placeholder="staffProfileData?.password" v-model="editedProfile.password">
          </div>
          <br>
          <!-- Add more fields for editing profile info as needed -->
          <div class="button-container" >
            <button class="buttons button-edit-profile cancel" @click="closePopup">Cancel</button>
            <button class="buttons button-edit-profile submit" @click="submitEdit">Submit</button>
          </div>

        </div>
      
    </div>
    </div>

    <!-- Main Content -->
    <div class="main-content" :class="{ 'blurred': showPopup }">
      <!-- Search bar here -->
      <!-- Add Staff button -->
      <div>
        <button type="button" class="buttons button-edit-profile" style= "margin-bottom: 0px;" @click="onAddStaffClick">Add Staff +</button>
      </div>

      <div class="table-Style">
        <table class="table table-striped table-hover table-hover">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Department</th>
              <th scope="col">Position</th>
              <th scope="col">Balance</th>
              <th scope="col">E-mail</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(staff, index) in staffData" :key="index">
              <td><span>{{ staff.username}}</span></td>
              <td><span>{{ staff.department }}</span></td>
              <td><span>{{ staff.position }}</span></td>
              <td style="width: 200px;"> {{ staff.balance.replace(/\|/g, "\n") }}</td>
              <td><span>{{ staff.email }}</span></td>
              <td><button class="buttons" @click="viewDetails(staff.email)">View</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { defineComponent } from 'vue';
import { getUserList, MyProfile, EditMyProfile } from '../api';

export default defineComponent({
  name: "slaAdminStaff",
  data() {
    return {
      showPopup: false,
      showEditPopup: false,
      sidebarCollapsed: false,
      isSmallScreen: window.innerWidth <= 768,
      staffData: [],
      staffProfileData: {},
      editedProfile: {},
    };
  },  
  computed: {
    updatedEditedProfile() {
      return {
        username: this.editedProfile.username || this.staffProfileData.username,
        password: this.editedProfile.password || this.staffProfileData.password,
        address: this.editedProfile.address || this.staffProfileData.address,
        contact: this.editedProfile.contact || this.staffProfileData.contact,
        emergency_contact: this.editedProfile.emergency_contact || this.staffProfileData.emergency_contact,

        user_nric: this.staffProfileData.user_nric || this.staffProfileData.user_nric,
        company: this.staffProfileData.company || this.staffProfileData.company,
        department: this.staffProfileData.department || this.staffProfileData.department,
        position: this.staffProfileData.position || this.staffProfileData.position,
        email: this.staffProfileData.email || this.staffProfileData.email,
        // Add more profile fields as needed
      };
    },
  },

  methods: {
    onDashBoardClick() {
      this.$router.push("/slaAdmindb");
    },
    onDashBoardClick2() {
            this.$router.push("/slaadminleaveapply");
        },
    onCalendarClick() {
      this.$router.push("/slaAdminCalendar");
    },
    onMyLeavesClick() {
      // Replace with your actual route for My Leaves
      this.$router.push("/slaAdminMyLeaves");
    },
    onLeavesToApproveClick() {
      // Replace with your actual route for Leaves to Approve
      this.$router.push("slaAdminLeavesToApprove");
    },
    onStaffClick() {
      // Replace with your actual route for Staff
      this.$router.push("/slaAdminStaff");
    },
    onCodeOfConductClick() {
            const externalLink = "https://dpp-object-space.sgp1.digitaloceanspaces.com/res/Employee%20Handbook%202023.12.pdf";
            window.open(externalLink, '_blank');
        },
    onPendingLeavesClick() {
      // Handle pending leaves click
    },
    onLeaveLogsClick() {
      // Handle leave logs click
    },
    onBalanceClick() {
      // Handle balance click
    },
    onLogoutClick() {
      this.$router.push("/");
    },
    onEditProfileClick(){
      this.showEditPopup = !this.showEditPopup
      this.showPopup = false
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },

    handleResize() {
      this.isSmallScreen = window.innerWidth <= 768;
    },
    onAddStaffClick() {
      // Replace "/addStaff" with the actual route you want to navigate to
      this.$router.push("/slaAdminAddStaff");
    },
    
    closePopup() {
      // Method to close the popup
      this.showPopup = this.showEditPopup = false;
    },

    viewDetails(staff_email) {
    this.showPopup = true; // Show the popup

    MyProfile("", staff_email)
      .then(response => {
        console.log('Profile Requested Successfully:', response);
        this.staffProfileData = response;
      })
      .catch(error => {
        console.error('Profile Request Error:', error);
        // Handle profile request error (e.g., show error message to the user)
      });
    },

    async submitEdit() {
        // Perform API call or submit edited profile data
        EditMyProfile("", this.updatedEditedProfile, this.updatedEditedProfile.email)
        await new Promise(resolve => setTimeout(resolve, 500));
        // After successful submission, navigate to profile page
        this.closePopup()
      },

    
  },


  async mounted() {
    // Add an event listener to handle window resize
    window.addEventListener("resize", this.handleResize);
    var v = await getUserList(localStorage.sessionid)
    console.log(v.data)
    this.staffData = v.data;
    this.UserOnLeave
  },
  beforeUnmount() {
    // Remove the event listener to avoid memory leaks
    window.removeEventListener("resize", this.handleResize);
  },
  
  


  


});
</script>


<style scoped lang="scss">
/* Add your styles here */
.slaAdminStaff {
  display: flex;
  overflow: hidden;
  /* Hide the sidebar when collapsed */
}

.menu-icon {
  display: none;
  /* Initially hide the menu icon for larger screens */
  cursor: pointer;
}



.menu-icon span {
  display: block;
  height: 3px;
  width: 25px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

.sidebar {
  width: 250px;
  background-color: #000;
  color: #000;
  padding: 20px;
  padding-right: 0%;
  /* Set sidebar height to full viewport height */
  display: flex;
  flex-direction: column;
  transition: 0.4s;
  /* Add transition for smoother animation */
  background: #1e1e0eb2;
}

.title {
  padding-bottom: 20px;
  padding-top: 20px;
  color: rgba(0, 0, 0, 0.40);
  text-align: center;
  font-family: Century Gothic;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sidebar.collapsed {
  width: 0;
  overflow: hidden;
}

.logo {
  font-size: 20px;
  margin-bottom: 20px;
  color: #fbfadc85;
  text-align: center;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-right: 25px;
}

.sidebar-button {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #555;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  border-radius: 10px 0px 0px 10px;
  background: #000;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 100;
  color: #fbfadca4;
  line-height: normal;
  text-align: left;
  text-indent: 20px;
}

.sidebar-button.logout-btn {
  /* Style for logout button */
  cursor: pointer;
  margin-top: auto;
  /* Push logout button to the bottom */
  border-radius: 10px;
  background: #000;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  width: 100px;
  flex-shrink: 0;
  font-family: Century Gothic;
  color: #fbfadca4;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
  text-indent: 13px;
}

/*td span{
  display:flex;
  padding: 15% 0;
}*/

.addstaffbtn {
  /* Style for logout button */
  cursor: pointer;
  margin-top: auto;
  /* Push logout button to the bottom */
  border-radius: 10px;
  background: #000;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  width: 20%;
  padding-left: 0%;
  flex-shrink: 0;
  font-family: Century Gothic;
  color: #fbfadca4;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  font-size: 16px;

  text-indent: 13px;
  margin-left: auto;


}

/* Button to bring sidebar back in for smaller screens */
.show-sidebar-btn-small {
  position: fixed;
  top: 10px;
  left: 5px;
  width: 25px;
  height: 20px;
  z-index: 1000;
  cursor: pointer;
  display: block;
  background-color: rgba(0, 0, 0, 0.30);
  border: 0px;
}

/* Button to bring sidebar back in for larger screens */
.show-sidebar-btn {
  position: fixed;
  top: 20px;
  left: 10px;
  z-index: 1000;
  cursor: pointer;
  display: block;
}

.main-content {
  flex: 1;
  padding: 20px;
  background: #FBFADC;
  backdrop-filter: blur(5px);
  overflow-y: auto;
  height: 100vh;
  
}

.table-Style {
  flex: 1;
  padding: 25px;
  background: rgba(0, 0, 0, 0.30);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  margin-top: 20px;
  overflow-y: auto;
}

.searchbar {
  flex: 1;
  margin-top: 20px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.20);
  width: 100%;
  height: 45px;
  border: 0cap;
  padding-left: 30px;

}

.control-panel {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.control-tile {
  flex: 1;
  padding: 70px;
  /* here is where it changes once i start edditing tiles */
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.30);
}

.calendar {
  flex: 1;
  padding: 75px;
  /* here is where it changes once i start edditing tiles */
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.30);
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .sidebar {
    width: 0;
    overflow: hidden;
    padding: 0%;
  }

  .menu-icon {
    display: none;
  }

  /* Hide the show-sidebar-btn on small screens when sidebar is visible */
  .show-sidebar-btn {
    display: none;
    color: #00000060;
  }

  .calendar {
    flex: 1;
    padding: 45px;
    /* here is where it changes once i start edditing tiles */
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.30);
  }
  .addstaffbtn {
    width: 35%;
}

}



@import '../styles/styles.scss';

.full-page-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Set a higher z-index to ensure it appears above other content */
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1010; /* Ensure the popup content is on top of the overlay */
}

.blurred {
  filter: blur(5px); /* Apply blur effect */
  pointer-events: none; /* Disable pointer events on blurred content */
}

</style>
