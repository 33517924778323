<template>
  <div class="slaAdminMyLeaves">
    <!-- Menu Icon for small screens -->
    <div class="menu-icon" @click="toggleSidebar">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <!-- Sidebar Toggle Button for Smaller Screens -->
    <button v-if="isSmallScreen" class="show-sidebar-btn-small" @click="toggleSidebar"></button>

    <!-- Sidebar -->
    <div class="sidebar" :class="{ 'collapsed': sidebarCollapsed }">
      <div class="logo" v-if="role !== 'finance'">Secondlifeasia Admin</div>
      <div class="logo" v-if="role == 'finance'">Secondlifeasia Finance</div>
      <button class="sidebar-button" @click="onDashBoardClick">Dashboard</button>
      <button class="sidebar-button" @click="onCalendarClick">Calendar</button>
      <button class="sidebar-button" @click="onMyLeavesClick">Staff on Leave</button>
      <button class="sidebar-button" @click="onLeavesToApproveClick" v-if="role !== 'finance'">Leaves to Approve</button>
      <button class="sidebar-button" @click="onDashBoardClick2" v-if="role !== 'finance'">Leave to Apply</button>
      <button class="sidebar-button" @click="onStaffClick" v-if="role !== 'finance'">Staff Management</button>
      <button class="sidebar-button" @click="onCodeOfConductClick">Code Of Conduct</button>
      <!-- Styled the logout button as well -->
      <button v-b-toggle.sidebar-1 class="sidebar-button logout-btn" @click="onLogoutClick">Logout</button>
    </div>

    <!-- Main Content -->
    <div class="main-content">

      <div class="table-Style">
        <table class="table table-striped table-hover table-hover">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Start Date</th>
              <th scope="col">Leave Type</th>
              <th scope="col">Day Count</th>
              <th scope="col">Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in leaveData" :key="index">
              <td>{{ item.username }}</td>
              <td>{{ item.start_date }}</td>
              <td>{{ item.leave_type }}</td>
              <td>{{ item.total_leave }}</td>
              <td style="width: 200px;"><span>{{ item.balance}}</span> </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>
</template>

<script>
// Import BootstrapVue components
//import { BCalendar } from 'bootstrap-vue';
import { defineComponent } from 'vue';
import { UserOnLeave } from '@/api';


export default defineComponent({
  components: {
    // Register the BCalendar component
    // BCalendar,
  },
  name: "slaAdminMyLeaves",
  data() {
    return {
      sidebarCollapsed: false,
      isSmallScreen: window.innerWidth <= 768, // Check if the initial screen size is small
      leaveData: [
        {
          name: 'Jane Amos',
          startDate: '14, JAN, 2024',
          leaveType: 'EL',
          dayCount: 2,
        },
        {
          name: 'Jane Doe',
          startDate: '14, JAN, 2024',
          leaveType: 'EL',
          dayCount: 2,
        },
        // Add more data as needed
      ],
    };
  },

  methods: {
    onDashBoardClick() {
      this.$router.push("/slaAdmindb");
    },
    onDashBoardClick2() {
            this.$router.push("/slaadminleaveapply");
        },
    onCalendarClick() {
      this.$router.push("/slaAdminCalendar");
    },
    onMyLeavesClick() {
      // Replace with your actual route for My Leaves
      this.$router.push("/slaAdminMyLeaves");
    },
    onLeavesToApproveClick() {
      // Replace with your actual route for Leaves to Approve
      this.$router.push("slaAdminLeavesToApprove");
    },
    onStaffClick() {
      // Replace with your actual route for Staff
      this.$router.push("/slaAdminStaff");
    },
    onCodeOfConductClick() {
            const externalLink = "https://dpp-object-space.sgp1.digitaloceanspaces.com/res/Employee%20Handbook%202023.12.pdf";
            window.open(externalLink, '_blank');
        },
    onPendingLeavesClick() {
      // Handle pending leaves click
    },
    onLeaveLogsClick() {
      // Handle leave logs click
    },
    onBalanceClick() {
      // Handle balance click
    },
    onLogoutClick() {
      this.$router.push("/");
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },

    handleResize() {
      this.isSmallScreen = window.innerWidth <= 768;
    },
    async usersonLeave() {
      try {
        const formData = localStorage.sessionid

        const response = await UserOnLeave(formData);

        console.log('Leave Request Submitted:', response);
        
        for (const item of response.data) {
          // Access the "balance" property of each item and replace '|' with '\n'
          item.balance = item.balance.replace(/\|/g, "\n");
        }
        this.leaveData = response.data
        
       
      } catch (error) {
        console.error('Leave Request Error:', error);
        // Handle leave request error (e.g., show error message to the user)
      }
    },
  },
  mounted() {
    // Add an event listener to handle window resize
    window.addEventListener("resize", this.handleResize);
    this.usersonLeave()
  },
  beforeUnmount() {
    // Remove the event listener to avoid memory leaks
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
        role() {
        return localStorage.getItem('role');
        },
    },
});
</script>

<style scoped lang="scss">
/* Add your styles here */
.slaAdminMyLeaves {
  display: flex;
  overflow: hidden;
  /* Hide the sidebar when collapsed */
}

.menu-icon {
  display: none;
  /* Initially hide the menu icon for larger screens */
  cursor: pointer;
}

.menu-icon span {
  display: block;
  height: 3px;
  width: 25px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

.sidebar {
  width: 250px;
  background-color: #000;
  color: #000;
  padding: 20px;
  padding-right: 0%;
  /* Set sidebar height to full viewport height */
  display: flex;
  flex-direction: column;
  transition: 0.4s;
  /* Add transition for smoother animation */
  background: #1e1e0eb2;
}

.title {
  padding-bottom: 20px;
  padding-top: 20px;
  color: rgba(0, 0, 0, 0.40);
  text-align: center;
  font-family: Century Gothic;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sidebar.collapsed {
  width: 0;
  overflow: hidden;
}

.logo {
  font-size: 20px;
  margin-bottom: 20px;
  color: #fbfadc85;
  text-align: center;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-right: 25px;
}

.sidebar-button {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #555;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  border-radius: 10px 0px 0px 10px;
  background: #000;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 100;
  color: #fbfadca4;
  line-height: normal;
  text-align: left;
  text-indent: 20px;
}

.sidebar-button.logout-btn {
  /* Style for logout button */
  cursor: pointer;
  margin-top: auto;
  /* Push logout button to the bottom */
  border-radius: 10px;
  background: #000;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  width: 100px;
  flex-shrink: 0;
  font-family: Century Gothic;
  color: #fbfadca4;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
  text-indent: 13px;
}

/* Button to bring sidebar back in for smaller screens */
.show-sidebar-btn-small {
  position: fixed;
  top: 10px;
  left: 5px;
  width: 25px;
  height: 20px;
  z-index: 1000;
  cursor: pointer;
  display: block;
  background-color: rgba(0, 0, 0, 0.30);
  border: 0px;
}

/* Button to bring sidebar back in for larger screens */
.show-sidebar-btn {
  position: fixed;
  top: 20px;
  left: 10px;
  z-index: 1000;
  cursor: pointer;
  display: block;
}

.main-content {
  flex: 1;
  padding: 25px;
  background: #FBFADC;
  backdrop-filter: blur(5px);
  overflow-y: auto;
  height: 100vh;
}

.table-Style {
  flex: 1;
  padding: 25px;
  background: rgba(0, 0, 0, 0.30);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  margin-top: 20px;
  overflow-y: auto;
}

.searchbar {
  flex: 1;
  margin-top: 20px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.20);
  width: 100%;
  height: 45px;
  border: 0cap;
  padding-left: 30px;
}

.control-panel {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.control-tile {
  flex: 1;
  padding: 70px;
  /* here is where it changes once i start edditing tiles */
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.30);
}

.calendar {
  flex: 1;
  padding: 75px;
  /* here is where it changes once i start edditing tiles */
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.30);
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .sidebar {
    width: 0;
    overflow: hidden;
    padding: 0%;
  }

  .menu-icon {
    display: none;
  }

  /* Hide the show-sidebar-btn on small screens when sidebar is visible */
  .show-sidebar-btn {
    display: none;
    color: #00000060;
  }

  .calendar {
    flex: 1;
    padding: 45px;
    /* here is where it changes once i start edditing tiles */
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.30);
  }
  .table-Style {
  font-size: small;
}
}

@import '../styles/styles.scss';
</style>

