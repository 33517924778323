// api.js
import axios from 'axios';

const baseURL = 'https://hr-api.secondlifeasia.com/leave';

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const signIn = async (email, password, company, role) => {
  try {
    const response = await api.post('/sign-in', {
      email,
      password,
      company,
      role,
    });
    return response.data;
  } catch (error) {
    console.error('Error in signIn:', error);
    throw error;
  }
};

export const register = async (data) => {
  try {
    const response = await api.post('/register', data);
    return response.data;
  } catch (error) {
    console.error('Error in register:', error);
    throw error;
  }
};

export const getHolidayList = async (session_id) => {
  try {
    const response = await api.post('/holiday/list', { session_id });
    return response.data;
  } catch (error) {
    console.error('Error in getHolidayList:', error);
    throw error;
  }
};

export const getUserList = async (session_id) => {
  try {
    const response = await api.post('/user/list', { session_id });
    return response.data;
  } catch (error) {
    console.error('Error in getUserList:', error);
    throw error;
  }
};

export const getBalanceList = async (session_id) => {
  try {
    const response = await api.post('/balance/list', { session_id });
    return response.data;
  } catch (error) {
    console.error('Error in getBalanceList:', error);
    throw error;
  }
};

export const getRequestList = async (session_id) => {
  try {
    const response = await api.post('/request/list', { session_id });
    return response.data;
  } catch (error) {
    console.error('Error in getRequestList:', error);
    throw error;
  }
};

export const requestApply = async (data) => {
  try {
    const response = await api.post('/request/apply', data);
    return response.data;
  } catch (error) {
    console.error('Error in requestApply:', error);
    throw error;
  }
};

export const requestAuthorise = async (data) => {
  try {
    const response = await api.post('/authorise', data);
    return response.data;
  } catch (error) {
    console.error('Error in requestAuthorise:', error);
    throw error;
  }
};
export const UserOnLeave = async (session_id) => {
  try {
    const response = await api.post('/staff/onleave', { session_id });
    return response.data;
  } catch (error) {
    console.error('Error in UserOnLeave:', error);
    throw error;
  }
};

export const LeaveSettings = async (session_id) => {
  try {
    const response = await api.post('/settings', { session_id });
    return response.data;
  } catch (error) {
    console.error('Error in LeaveSettings:', error);
    throw error;
  }
};

export const HolidayTotal = async (session_id) => {
  try {
    const response = await api.post('/holiday/total', { session_id });
    return response.data;
  } catch (error) {
    console.error('Error in HolidayTotal:', error);
    throw error;
  }
};

export const MyProfile = async (session_id="", email="") => {
  try {
    const response = await api.post('personal/profile', {session_id, email});
    return response.data;
  } catch (error) {
    console.error('Error in MyProfile:', error);
    throw error;
  }
};

export const EditMyProfile = async (session_id, data, email="") => {
  
  try {
    const response = await api.post('personal/editprofile', {session_id, data, email});
    return response.data;
  } catch (error) {
    console.error('Error in EditMyProfile:', error);
    throw error;
  }
};

// Add more methods as needed...

export default api;
