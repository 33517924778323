<template>
  <div class="slaStaffdb">

    <!-- Menu Icon for small screens -->
    <div class="menu-icon" @click="toggleSidebar">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <!-- Sidebar Toggle Button for Smaller Screens -->
    <button v-if="isSmallScreen" class="show-sidebar-btn-small" @click="toggleSidebar"></button>

    <!-- Sidebar -->
    <div id="sidebar" class="sidebar" :class="{ 'collapsed': sidebarCollapsed }">

        <div class="logo">Secondlifeasia Admin</div>
        <button class="sidebar-button" @click="onDashBoardClick">Dashboard</button>
        <button class="sidebar-button" @click="onCalendarClick">Calendar</button>
        <button class="sidebar-button" @click="onMyLeavesClick">Staff on Leave</button>
        <button class="sidebar-button" @click="onLeavesToApproveClick">Leaves to Approve</button>
        <button class="sidebar-button" @click="onDashBoardClick2">Leave to Apply</button>
        <button class="sidebar-button" @click="onStaffClick">Staff Management</button>
        <button class="sidebar-button" @click="onCodeOfConductClick">Code Of Conduct</button>
        <!-- Styled the logout button as well -->
        <button v-b-toggle.sidebar-1 class="sidebar-button logout-btn" @click="onLogoutClick">Logout</button>
    </div>

    <!-- Main Content -->
    <div class="main-content">

      <!-- Control Panel -->
      <div class="control-panel">
        <div class="control-tile" @click="onPendingLeavesClick">
          <h4><b>Request Leave</b></h4>
          <div>
            <div class="mb-3">
              <label for="leaveType" class="form-label gap">Leave Type</label>
              <select v-model="leaveType" class="form-select" id="leaveType">
                <option v-for="(type, index) in leaveTypes" :key="index" :value="type.value">{{ type.option }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="startDate" class="form-label gap">Start Date</label>
              <input type="date" v-model="startDate" class="form-control" id="startDate">
            </div>
            <div class="mb-3">
              <label for="endDate" class="form-label gap">End Date</label>
              <input type="date" v-model="endDate" class="form-control" id="endDate">
            </div>
            <div class="mb-3">
              <label for="reason" class="form-label gap">Reason</label>
              <textarea v-model="reason" class="form-control" id="reason" rows="3"></textarea>
            </div>
            <!-- Add a spinner that is conditionally displayed -->
            <div v-if="isProcessing" class="spinner-border text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <!-- Display the Request button when not processing -->
            <button @click="submitRequest" class="requestbtn gap" :disabled="isProcessing">Request</button>
          </div>
        </div>

      
    </div>

      

    </div>
  </div>

</template>

<script>
import { defineComponent } from "vue";
import { requestApply, getBalanceList, getRequestList, LeaveSettings, HolidayTotal } from '../api';

export default defineComponent({
  name: "GgStaffDb",
  data() {
    return {
      sidebarCollapsed: false,
      isSmallScreen: window.innerWidth <= 768,
      attributes: [
        // Add more attributes as needed
      ],
      leaveType: 'Medical',
      startDate: '',
      endDate: '',
      reason: '',
      staffData: [],
      balanceData: [],
      leaveTypes: [
        { value: 'ML', label: 'Medical Leave' },
        //{ value: 'Emergency', label: 'Emergency Leave' },
        { value: 'AL', label: 'Annual Leave' },
        // Add more leave types as needed
      ],
      isProcessing: false, // New property to indicate if the request is being processed
    };
  },

  methods: {
    onDashBoardClick() {
            this.$router.push("/slaAdmindb");
        },
        onDashBoardClick2() {
            this.$router.push("/slaadminleaveapply");
        },
        onCalendarClick() {
            this.$router.push("/slaAdminCalendar");
        },
        onMyLeavesClick() {
            this.$router.push("/slaAdminMyLeaves");
        },
        onLeavesToApproveClick() {
            this.$router.push("slaAdminLeavesToApprove");
        },
        onStaffClick() {
            this.$router.push("/slaAdminStaff");
        },

        onCodeOfConductClick() {
            const externalLink = "https://dpp-object-space.sgp1.digitaloceanspaces.com/res/Employee%20Handbook%202023.12.pdf";
            window.open(externalLink, '_blank');
        },


        onPendingLeavesClick() {
            // Handle pending leaves click
        },
        onLeaveLogsClick() {
            // Handle leave logs click
        },
        onBalanceClick() {
            // Handle balance click
        },
    onLogoutClick() {
      this.$router.push("/");
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },

    handleResize() {
      this.isSmallScreen = window.innerWidth <= 768;
    },

    async banceList() {
      try {
        const formData = localStorage.sessionid

        const response = await getBalanceList(formData);

        console.log('Leave Request Submitted:', response);
        this.balanceData = response.data

      } catch (error) {
        console.error('Leave Request Error:', error);
        // Handle leave request error (e.g., show error message to the user)
      }

    },
    async myLeavs() {
      try {
        const formData = localStorage.sessionid

        const response = await getRequestList(formData);

        console.log('Leave Request Submitted:', response);
        this.staffData = response.data

      } catch (error) {
        console.error('Leave Request Error:', error);
        // Handle leave request error (e.g., show error message to the user)
      }
    },

    async submitRequest() {
      try {
        // Set isProcessing to true to show the spinner
        this.isProcessing = true;

        const formData = {
          session_id: localStorage.sessionid,
          leave_name: this.leaveType,
          start_date: this.startDate,
          end_date: this.endDate,
          reason: this.reason,
        };

        const response = await requestApply(formData);

        console.log('Leave Request Submitted:', response);

        // Optionally, handle success and update the UI or navigate to another page
        // ...

        // Reset the form after submission (optional)
        this.resetForm();
        this.myLeavs();
      } catch (error) {
        console.error('Leave Request Error:', error);
        // Handle leave request error (e.g., show error message to the user)
      } finally {
        // Reset isProcessing when the request is completed (whether successful or not)
        this.isProcessing = false;
      }
    },
    async leavebalance() {
      try {
        const formData = localStorage.sessionid

        const response = await LeaveSettings(formData);

        console.log('Leave Request Submitted:', response);
        this.leaveTypes = response.data.l_options
        //this.pendingLeavesData = response.data
        //this.leaveLogsData = response.data

      } catch (error) {
        console.error('Leave Request Error:', error);
        // Handle leave request error (e.g., show error message to the user)
      }
    },

    resetForm() {
      this.leaveType = 'Medical';
      this.startDate = '';
      this.endDate = '';
      this.reason = '';
    },
    async holidayTotal() {
      var v = await HolidayTotal(localStorage.sessionid)
      console.log(v.data)
      var itemss = this.attributes.concat()
      var indexes = 0
      for (const item of v.data) {
        // Manipulate or perform operations on each item as needed
        // For example, you can modify properties or add new ones
        var itemsss = {
          key: item.Key,
          customData: {
            "description": item.Name,
            "description2": item.Reason
          },
          dates: [
            {
              "start": item.Start_Date,
              "end": item.End_Date
            }
          ],
          dot: {
            color: item.Color,
            fillMode: 'solid',
          },
          popover: true,
          order: indexes,
          hover: {
            backgroundColor: 'lightblue',
          },
        }
        itemss.push(itemsss)
        indexes++;
        // Add the modified item to the loopedArray
        //this.loopedArray.push(item);
      }
      console.log(itemss)

      this.attributes = itemss
      console.log(this.attributes)
      //this.holidayData = v.data
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.banceList()
    this.myLeavs()
    this.leavebalance()
    this.holidayTotal()
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
});
</script>


<style scoped lang="scss">
/* Add your styles here */
.slaStaffdb {
  display: flex;
  overflow: hidden;
  /* Hide the sidebar when collapsed */
}

.menu-icon {
  display: none;
  /* Initially hide the menu icon for larger screens */
  cursor: pointer;
}

.menu-icon span {
  display: block;
  height: 3px;
  width: 25px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

.sidebar {
  width: 250px;
  background-color: #000;
  color: #000;
  padding: 20px;
  padding-right: 0%;
  /* Set sidebar height to full viewport height */
  display: flex;
  flex-direction: column;
  transition: 0.4s;
  /* Add transition for smoother animation */
  background: #1e1e0eb2;
}

.title {
  padding-bottom: 20px;
  padding-top: 20px;
  color: rgba(0, 0, 0, 0.40);
  text-align: center;
  font-family: Century Gothic;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sidebar.collapsed {
  width: 0;
  overflow: hidden;
}

.logo {
  font-size: 20px;
  margin-bottom: 20px;
  color: #fbfadc85;
  text-align: center;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-right: 25px;
}

.sidebar-button {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #555;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  border-radius: 10px 0px 0px 10px;
  background: #000;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 100;
  color: #fbfadca4;
  line-height: normal;
  text-align: left;
  text-indent: 20px;
}

.sidebar-button.logout-btn {
  /* Style for logout button */
  cursor: pointer;
  margin-top: auto;
  /* Push logout button to the bottom */
  border-radius: 10px;
  background: #000;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  width: 100px;
  flex-shrink: 0;
  font-family: Century Gothic;
  color: #fbfadca4;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
  text-indent: 13px;
}

.requestbtn {
  cursor: pointer;
  margin-top: auto;
  border-radius: 10px;
  background: #000;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  flex-shrink: 0;
  font-family: Century Gothic;
  color: #fbfadca4;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 20px;
}

/* Button to bring sidebar back in for smaller screens */
.show-sidebar-btn-small {
  position: fixed;
  top: 10px;
  left: 5px;
  width: 25px;
  height: 20px;
  z-index: 1000;
  cursor: pointer;
  display: block;
  background-color: rgba(0, 0, 0, 0.30);
  border: 0px;
}

/* Button to bring sidebar back in for larger screens */
.show-sidebar-btn {
  position: fixed;
  top: 20px;
  left: 10px;
  z-index: 1000;
  cursor: pointer;
  display: block;
}

.main-content {
  flex: 1;
  padding: 20px;
  background: #FBFADC;
  backdrop-filter: blur(5px);
  overflow-y: auto;
  height: 100vh;
}

.control-panel {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.control-tile {
  flex: 1;
  padding: 15px;
  /* here is where it changes once I start editing tiles */
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.30);
}

.calendar {
  flex: 1;
  padding: 25px;
  /* here is where it changes once I start editing tiles */
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0);
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .sidebar {
    width: 0;
    overflow: hidden;
    padding: 0%;
  }

  .menu-icon {
    display: none;
  }

  /* Hide the show-sidebar-btn on small screens when the sidebar is visible */
  .show-sidebar-btn {
    display: none;
    color: #00000060;
  }

  .calendar {
    flex: 1;
    padding: 25px;
    /* here is where it changes once I start editing tiles */
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 15px;
    background: rgba(0, 0, 0);
  }
}

@import '../styles/styles.scss';
</style>
