<template>
  <div :class="[$style.welcome, { [$style.blurredBackground]: isBackgroundBlurred }]">

    
   <!-- <div class="$style.dropdownContainer">
      <button :class="$style.comggbtn" @click.stop="onComGGbtnClick">
        <b :class="$style.buttonText">Guard Genius</b>
      </button>
      <teleport to="body">
        <div v-show="showGGDropdown" ref="ggDropdown" :class="$style.dropdownContent">
          <button :class="$style.loginBtn" @click="onAdminLogin('gg')">Admin</button>
          <button :class="$style.loginBtn" @click="onStaffLogin('gg')">Staff</button>
        </div>
      </teleport>
    </div> -->


    <div class="$style.dropdownContainer">
      <button :class="$style.comslabtn" @click.stop="onComSLAbtnClick">
        <b :class="$style.buttonText">Secondlifeasia</b>
      </button>
      <teleport to="body">
        <div v-show="showSLADropdown" ref="slaDropdown" :class="$style.dropdownContent">
          <button :class="$style.loginBtn" @click="onAdminLogin('sla')">Admin</button>
          <button :class="$style.loginBtn" @click="onStaffLogin('sla')">Staff</button>
        </div>
      </teleport>
    </div>
    <div :class="$style.welcomeChild" />
    <div :class="$style.welcomePeterContainer">
      <p :class="$style.welcome1">
        <b>Welcome</b>
      </p>
      <p :class="$style.weAreThrilled">
        We are thrilled to introduce our new and improved Leave Management System! "Marcus", Designed with
        simplicity and efficiency in mind, our system streamlines the leave request process,
        making it easier for you to manage your time off.
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "WelcomePage",
  data() {
    return {
      showGGDropdown: false,
      showSLADropdown: false,
      isBackgroundBlurred: false,
    };
  },
  methods: {
    onComGGbtnClick() {
      this.showGGDropdown = !this.showGGDropdown;
      this.isBackgroundBlurred = this.showGGDropdown;

      // Close the SLA dropdown if open
      if (this.showSLADropdown) {
        this.showSLADropdown = false;
      }
    },
    onComSLAbtnClick() {
      this.showSLADropdown = !this.showSLADropdown;
      this.isBackgroundBlurred = this.showSLADropdown;

      // Close the GG dropdown if open
      if (this.showGGDropdown) {
        this.showGGDropdown = false;
      }
    },
    onAdminLogin(type) {
      this.$router.push(`/${type}adminlogin`);
    },
    onStaffLogin(type) {
      this.$router.push(`/${type}stafflogin`);
    },
  },
});
</script>


<style module>
  .blurredBackground {
    filter: blur(5px);
  }

  .dropdownContainer {
    position: relative;
    display: inline-block;
    margin: 0 10px;
    
  }

  .dropdownContent {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 200px; /* Adjust the bottom position as needed */
    left: 50%; /* Center the drop-down menu horizontally */
    transform: translateX(-50%); /* Center the drop-down menu horizontally */
    background-color: var(--color-white);
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border: 0px solid var(--color-white);
    border-radius: 10px; /* Adjust the border radius as needed */
    border-bottom: 0px solid; /* Adjust the border style as needed */
    background: rgba(190, 190, 68, 0.099); /* Adjust the background color and opacity as needed */
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25); /* Adjust the box-shadow as needed */
    width: 40%; /* Adjust the width as needed */
    height: 0px; /* Adjust the height as needed */
    flex-shrink: 0; /* Prevent flex shrink */
  }

  .comggbtn,
  .comslabtn {
    position: absolute;
    width: 45%;
    height: 8%;
    background-color: #ffe358;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border: 2px solid var(--color-white);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-lg);
    font-weight: bold;
    color: var(--color-darkolivegreen);
    text-align: center;
  }

  .buttonText {
    color: var(--color-darkolivegreen);
    font-weight: bold;
    font-size: 25px;
  }

  .comggbtn {
    top: 80%;
    left: 5%;
  }

  .comslabtn {
    top: 80%;
    right: 27%;
  }

  .welcomeChild {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 81.5%;
    border-radius: 0;
    background-color: #013042;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  }

  .welcome1 {
    margin: 10;
    font-size: var(--font-size-23xl);
    color: rgba(243, 244, 245, 0.9);
  }

  .weAreThrilled {
    margin: 0;
    color: rgba(243, 244, 245, 0.9);
  }

  .welcomePeterContainer {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: var(--font-size-lg);
    color: var(--color-gray-1300);
  }

  .welcome {
    position: relative;
    background-color: #fae994;
    width: 100%;
    height: 100vh;
    overflow: auto;
    text-align: center;
    font-size: var(--font-size-23xl);
    color: var(--color-darkolivegreen);
    font-family: var(--font-century-gothic);
  }
  

  button {
      width: 100%; /* Make buttons full-width */
      padding: 12px;
      margin-top: 10px; /* Add margin between buttons */
      background-color: #000; /* Change the background color to black */
      color: var(--color-lightyellow-100); /* Button text color */
      border: 1px solid var(--color-darkolivegreen); /* Button border color */
      border-radius: 10px; /* Button border radius */
      cursor: pointer;
      font-size: var(--font-size-lg); /* Button font size */
      font-weight: bold; /* Button font weight */
      transition: background-color 0.3s; /* Button transition effect */

      /* Hover effect */
      &:hover {
        background-color: var(--color-darkolivegreen-darker); /* Adjust the hover background color */
      }
    }

  .loginBtn {
    width: 100%;
    padding: 12px;
    background: #ffe358;
    color: var(--color-lightyellow-100);
    border: 2px solid #000;
    border-radius: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);

    color: #11202fb0;
    text-align: center;
    font-style: normal;
    line-height: normal;
  }
</style>


