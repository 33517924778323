<template>
  <div class="ggAdminAddStaff">
    <!-- Menu Icon for small screens -->
    <div class="menu-icon" @click="toggleSidebar">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <!-- Sidebar Toggle Button for Smaller Screens -->
    <button v-if="isSmallScreen" class="show-sidebar-btn-small" @click="toggleSidebar"></button>


    <!-- Sidebar -->
    <div class="sidebar" :class="{ 'collapsed': sidebarCollapsed }">
      <div class="logo">Guard Genius Admin</div>
      <button class="sidebar-button" @click="onDashBoardClick">Dashboard</button>
      <button class="sidebar-button" @click="onCalendarClick">Calendar</button>
      <button class="sidebar-button" @click="onMyLeavesClick">Staff on Leave</button>
      <button class="sidebar-button" @click="onLeavesToApproveClick">Leaves to Approve</button>
      <button class="sidebar-button" @click="onStaffClick">Staff Management</button>
      <!-- Styled the logout button as well -->
      <button v-b-toggle.sidebar-1 class="sidebar-button logout-btn" @click="onLogoutClick">Logout</button>
    </div>

    <div class="main-content">

      <!--Search bar here-->
      <!-- Add Staff button -->
      <div>
        <button type="button" class="addstaffbtn" @click="onAddStaffClick">Add +</button>
      </div>



      <div class="staffForm">

        <form action="/submit_registration" method="post">
          <div class="title"><b>Staff Details</b></div>
          <!-- Full Name -->
          <div class="mb-3">
            <label for="fullName" class="form-label">Full Name</label>
            <input type="text" class="form-control" id="fullName" name="fullName" v-model="fullName" required>
          </div>

          <!-- Department Dropdown -->
          <div class="mb-3">
            <label for="department" class="form-label">Department</label>
            <select class="form-select" id="department" name="department" required v-model="department">
              <option value="" selected disabled>Select Department</option>
              <option value="repair">Repair</option>
              <option value="product">Product</option>
              <option value="devOps">Development Oparetions</option>
              <!-- Add more departments as needed -->
            </select>
          </div>

          <!-- Position Dropdown -->
          <div class="mb-3">
            <label for="position" class="form-label">Position</label>
            <select class="form-select" id="position" name="position" required v-model="position">
              <option value="" selected disabled>Select Position</option>
              <option value="technician">Technician</option>
              <option value="manager">Manager</option>
              <option value="headOfDevOps">Head of Development and Oparetion</option>
              <option value="productSpecialist">Product Specialist</option>
              <option value="backendDevOps">Backend Development Oparetion</option>
              <option value="frontendDevOps">Frontend Development Oparetion</option>
              <!-- Add more positions as needed -->
            </select>
          </div>

          <!-- Email -->
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" class="form-control" id="email" name="email" required v-model="email">
          </div>

          <!-- NRIC/Passport No. -->
          <div class="mb-3">
            <label for="nric/passport" class="form-label">NRIC/PASSPORT</label>
            <input type="nric/passport" class="form-control" id="nric/passpot" name="nric/passport" required
              v-model="nric_passport">
          </div>

          <!-- Set Password -->
          <div class="mb-3">
            <label for="password" class="form-label">Set Password</label>
            <input type="password" class="form-control" id="password" name="password" required v-model="password">
          </div>

          <!-- Year Balance -->
          <div class="mb-3">
            <label for="yearBalance" class="form-label">Year Balance</label>
            <input type="text" class="form-control" id="yearBalance" name="yearBalance" required v-model="yearBalance">
          </div>

        </form>

      </div>




    </div>

  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { register } from '../api';

export default defineComponent({
  name: "ggAdminAddStaff",

  data() {
    return {
      sidebarCollapsed: false,
      isSmallScreen: window.innerWidth <= 768,
      fullName: "",
      department: "",
      position: "",
      email: "",
      nric_passport: "",
      password: "",
      yearBalance: "",
    };
  },

  methods: {
    onDashBoardClick() {
      this.$router.push("/ggAdmindb");
    },

    onCalendarClick() {
      this.$router.push("/ggAdminCalendar");
    },

    onMyLeavesClick() {
      this.$router.push("/ggAdminMyLeaves");
    },

    onLeavesToApproveClick() {
      this.$router.push("ggAdminLeavesToApprove");
    },

    onStaffClick() {
      this.$router.push("/ggAdminStaff");
    },

    onPendingLeavesClick() {
      // Handle pending leaves click
    },

    onLeaveLogsClick() {
      // Handle leave logs click
    },

    onBalanceClick() {
      // Handle balance click
    },

    onLogoutClick() {
      this.$router.push("/");
    },

    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },

    handleResize() {
      this.isSmallScreen = window.innerWidth <= 768;
    },

    async onAddStaffClick() {
      try {
        const formData = {
          fullname: this.fullName,
          department: this.department,
          position: this.position,
          email: this.email,
          nric: this.nric_passport,
          password: this.password,
          year_balance: this.yearBalance,
          company: "guardgenius",
          session_id: localStorage.sessionid
        };

        console.log(formData);

        const response = await register(formData);

        console.log('Registration Response:', response);
        if (response.result === 'user registered') {
          this.$router.push("/ggAdminStaff");
        }
        // Optionally, handle success and redirect to another page
        // this.$router.push("/ggAdminStaff");
      } catch (error) {
        console.error('Registration Error:', error);
        // Handle registration error (e.g., show error message to the user)
      }
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
});
</script>



<style scoped>
/* Add your styles here */
.ggAdminAddStaff {
  display: flex;
  overflow: hidden;
  /* Hide the sidebar when collapsed */
}

.menu-icon {
  display: none;
  /* Initially hide the menu icon for larger screens */
  cursor: pointer;
}



.menu-icon span {
  display: block;
  height: 3px;
  width: 25px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

.sidebar {
  width: 250px;
  background-color: #000;
  color: #000;
  padding: 20px;
  padding-right: 0%;
  /* Set sidebar height to full viewport height */
  display: flex;
  flex-direction: column;
  transition: 0.4s;
  /* Add transition for smoother animation */
  background: #1e1e0eb2;

}

.title {
  padding-bottom: 20px;
  padding-top: 20px;
  color: rgba(0, 0, 0, 0.40);
  text-align: center;
  font-family: Century Gothic;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sidebar.collapsed {
  width: 0;
  overflow: hidden;
}

.logo {
  font-size: 20px;
  margin-bottom: 20px;
  color: #fbfadc85;
  text-align: center;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-right: 25px;
}

.sidebar-button {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #555;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  border-radius: 10px 0px 0px 10px;
  background: #000;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 100;
  color: #fbfadca4;
  line-height: normal;
  text-align: left;
  text-indent: 20px;
}

.sidebar-button.logout-btn {
  /* Style for logout button */
  cursor: pointer;
  margin-top: auto;
  /* Push logout button to the bottom */
  border-radius: 10px;
  background: #000;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  width: 100px;
  flex-shrink: 0;
  font-family: Century Gothic;
  color: #fbfadca4;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
  text-indent: 13px;
}

.addstaffbtn {
  /* Style for logout button */
  cursor: pointer;
  margin-top: auto;
  margin-left: auto;
  /* Push logout button to the bottom */
  border-radius: 10px;
  background: #000;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  width: 15%;
  padding-left: 0%;
  flex-shrink: 0;
  font-family: Century Gothic;
  color: #fbfadca4;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  font-size: 16px;

  text-indent: 13px;
  margin-left: auto;


}

/* Button to bring sidebar back in for smaller screens */
.show-sidebar-btn-small {
  position: fixed;
  top: 10px;
  left: 5px;
  width: 25px;
  height: 20px;
  z-index: 1000;
  cursor: pointer;
  display: block;
  background-color: rgba(0, 0, 0, 0.30);
  border: 0px;
}

/* Button to bring sidebar back in for larger screens */
.show-sidebar-btn {
  position: fixed;
  top: 20px;
  left: 10px;
  z-index: 1000;
  cursor: pointer;
  display: block;
}

.main-content {
  flex: 1;
  padding: 20px;
  background: #FBFADC;
  backdrop-filter: blur(5px);
  overflow-y: auto;
  height: 100vh;
}

.table-Style {
  flex: 1;
  padding: 25px;
  background: rgba(0, 0, 0, 0.30);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  margin-top: 20px;
}

.searchbar {
  flex: 1;
  margin-top: 20px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.20);
  width: 100%;
  height: 45px;
  border: 0cap;
  padding-left: 30px;

}

.control-panel {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.control-tile {
  flex: 1;
  padding: 70px;
  /* here is where it changes once i start edditing tiles */
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.30);
}

.staffForm {
  flex: 1;
  padding-top: 20px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 70px;
  margin-top: 30px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.30);
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .sidebar {
    width: 0;
    overflow: hidden;
    padding: 0%;
  }

  .menu-icon {
    display: none;
  }

  /* Hide the show-sidebar-btn on small screens when sidebar is visible */
  .show-sidebar-btn {
    display: none;
    color: #00000060;
  }

  .calendar {
    flex: 1;
    padding: 45px;
    /* here is where it changes once i start edditing tiles */
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.30);
  }

  .addstaffbtn[data-v-573dc67a] {
    
    width: 50%;
    
}

.staffForm[data-v-573dc67a] {
    flex: 1;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 35px;
    margin-top: 15px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.30);
}

}
</style>
