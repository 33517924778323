<template>
    <div class="ggadmindb">
        <!-- Menu Icon for small screens -->
        <div class="menu-icon" @click="toggleSidebar">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <!-- Sidebar Toggle Button for Smaller Screens -->
        <button v-if="isSmallScreen" class="show-sidebar-btn-small" @click="toggleSidebar"></button>

        
        <!-- Sidebar -->
        <!--<div class="collapse collapse-horizontal" id="collapseWidthExample">-->
        <!-- <div class="sidebar" style="width: 300px;">-->

        <div id="sidebar-1" class="sidebar" :class="{ 'collapsed': sidebarCollapsed }">

            <div class="logo">Guard Genius Admin</div>
            <button class="sidebar-button" @click="onDashBoardClick">Dashboard</button>
            <button class="sidebar-button" @click="onCalendarClick">Calendar</button>
            <button class="sidebar-button" @click="onMyLeavesClick">Staff on Leave</button>
            <button class="sidebar-button" @click="onLeavesToApproveClick">Leaves to Approve</button>
            <button class="sidebar-button" @click="onStaffClick">Staff Management</button>
            <!-- Styled the logout button as well -->
            <button v-b-toggle.sidebar-1 class="sidebar-button logout-btn" @click="onLogoutClick">Logout</button>
        </div>
        <!-- </div>-->
        <!-- </div>-->
        <!--</div>-->

        <!-- Main Content -->
        <div class="main-content">
            <!-- Control Panel -->
            <div class="title"><b>Control Panel</b></div>
            <div class="control-panel">
                <!-- Pending Leaves -->
                <div class="control-tile" @click="onPendingLeavesClick">
                    <b>Pending Leaves</b>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Leave Type</th>
                                <th scope="col">Days</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in pendingLeavesData" :key="index">
                                <td v-if="item.status === 'Pending'">{{ item.username }}</td>
                                <td v-if="item.status === 'Pending'">{{ item.leave_type }}</td>
                                <td v-if="item.status === 'Pending'">{{ item.total_leave }}</td>
                                <td v-if="item.status === 'Pending'">{{ item.status }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Leave Logs -->
                <div class="control-tile" @click="onLeaveLogsClick">
                    <b>Leave Logs</b>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Leave Type</th>
                                <th scope="col">Days</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in leaveLogsData" :key="index">
                                <td v-if="item.status !== 'Pending'">{{ item.username }}</td>
                                <td v-if="item.status !== 'Pending'">{{ item.leave_type }}</td>
                                <td v-if="item.status !== 'Pending'">{{ item.total_leave }}</td>
                                <td v-if="item.status !== 'Pending'">{{ item.status }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Balance -->
                <div class="control-tile" @click="onBalanceClick">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Leave Type</th>
                                <th scope="col">Set Limit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in balanceData" :key="index">
                                <td>{{ item.types }}</td>
                                <td>{{ item.value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Calendar -->
            <div class="calendar">
        <VCalendar
          transparent
          borderless
          expanded
          title-position="right"
          is-dark="{selector: ':root', darkClass: 'dark'}"
          :rows="1"
          :attributes="attributes"
        >
        <template #day-popover = "{ attributes,order}">
            <div v-for="{ key, customData } in attributes" :key="key" class="text-xs text-gray-700 dark:text-gray-300">
              {{ order }}
              {{ customData.description }}
              {{ customData.description2 }}
            </div>
            <!--{{ attributes[0].customData.description }}-->
          </template>
        </VCalendar>

      </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { getRequestList, LeaveSettings, HolidayTotal } from "@/api";

export default defineComponent({
    name: "GgAdminDb",
    data() {
        return {
            sidebarCollapsed: false,
            isSmallScreen: window.innerWidth <= 768,
            pendingLeavesData: [],
            balanceData: [],
            attributes: [],
            holidayData: [],
        };
    },

    methods: {
        onDashBoardClick() {
            this.$router.push("/ggAdmindb");
        },
        onCalendarClick() {
            this.$router.push("/ggAdminCalendar");
        },
        onMyLeavesClick() {
            this.$router.push("/ggAdminMyLeaves");
        },
        onLeavesToApproveClick() {
            this.$router.push("ggAdminLeavesToApprove");
        },
        onStaffClick() {
            this.$router.push("/ggAdminStaff");
        },
        onPendingLeavesClick() {
            // Handle pending leaves click
        },
        onLeaveLogsClick() {
            // Handle leave logs click
        },
        onBalanceClick() {
            // Handle balance click
        },
        onLogoutClick() {
            this.$router.push("/");
        },
        toggleSidebar() {
            this.sidebarCollapsed = !this.sidebarCollapsed;
        },

        handleResize() {
            this.isSmallScreen = window.innerWidth <= 768;
        },

        async leavebalance() {
            try {
                const formData = localStorage.sessionid

                const response = await LeaveSettings(formData);

                console.log('Leave Request Submitted:', response);
                this.balanceData = response.data.l_values
                //this.pendingLeavesData = response.data
                //this.leaveLogsData = response.data

            } catch (error) {
                console.error('Leave Request Error:', error);
                // Handle leave request error (e.g., show error message to the user)
            }

        },
        async staffrequestlist() {
            try {
                const formData = localStorage.sessionid

                const response = await getRequestList(formData);

                console.log('Leave Request Submitted:', response);
                this.pendingLeavesData = response.data
                this.leaveLogsData = response.data

            } catch (error) {
                console.error('Leave Request Error:', error);
                // Handle leave request error (e.g., show error message to the user)
            }
        },
        async holidayTotal () {
      var v = await HolidayTotal(localStorage.sessionid)
      console.log(v.data)
      var itemss = this.attributes.concat()
      var indexes = 0
      for (const item of v.data) {
        // Manipulate or perform operations on each item as needed
        // For example, you can modify properties or add new ones
        var itemsss = {
          key:item.Key,
          customData:{
            "description":item.Name,
            "description2":item.Reason
          },
          dates:[
            {
              "start":item.Start_Date,
              "end":item.End_Date
            }
          ],
          dot: {
              color: item.Color,
              fillMode: 'solid',
            },
          popover: true,
          order: indexes,
          hover: {
            backgroundColor: 'lightblue',
          },
        }
        itemss.push(itemsss)
        indexes++;
        // Add the modified item to the loopedArray
        //this.loopedArray.push(item);
      }
      console.log(itemss)
      
      this.attributes = itemss
      console.log(this.attributes)
      //this.holidayData = v.data
    },
    },

    mounted() {
        // Add an event listener to handle window resize
        window.addEventListener("resize", this.handleResize);
        this.staffrequestlist()
        this.leavebalance()
        this.holidayTotal()
    },

    beforeUnmount() {
        // Remove the event listener to avoid memory leaks
        window.removeEventListener("resize", this.handleResize);
    },
});
</script>

<style scoped>
/* Add your styles here */
element.style {
    height: 100vh;
}

.ggadmindb {
    display: flex;
    overflow: hidden;
    /* Hide the sidebar when collapsed */
}

.menu-icon {
    display: none;
    /* Initially hide the menu icon for larger screens */
    cursor: pointer;
}



.menu-icon span {
    display: block;
    height: 3px;
    width: 25px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
}

.sidebar {
    width: 250px;
    background-color: #000;
    color: #000;
    padding: 20px;
    padding-right: 0%;
    display: flex;
    flex-direction: column;
    transition: 0.4s;
    background: #1e1e0eb2;
}

.title {
    padding-bottom: 5px;
    padding-top: 10px;
    color: rgba(0, 0, 0, 0.40);
    text-align: center;
    font-family: Century Gothic;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sidebar.collapsed {
    width: 0;
    overflow: hidden;
    padding: 0;
    transition: 0.2s;
}


.logo {
    font-size: 20px;
    margin-bottom: 20px;
    color: #fbfadc85;
    text-align: center;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-right: 25px;
}

.sidebar-button {
    cursor: pointer;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #555;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    border-radius: 10px 0px 0px 10px;
    background: #000;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 100;
    color: #fbfadca4;
    line-height: normal;
    text-align: left;
    text-indent: 20px;
}

.sidebar-button.logout-btn {
    /* Style for logout button */
    cursor: pointer;
    margin-top: auto;
    /* Push logout button to the bottom */
    border-radius: 10px;
    background: #000;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
    width: 100px;
    flex-shrink: 0;
    font-family: Century Gothic;
    color: #fbfadca4;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 30px;
    text-indent: 13px;
}

/* Button to bring sidebar back in for smaller screens */
.show-sidebar-btn-small {
    position: fixed;
    top: 0;
    left: 5px;
    width: 25px;
    height: 20px;
    z-index: 1000;
    cursor: pointer;
    display: block;
    background-color: rgba(0, 0, 0, 0.30);
    border: 0px;
}

/* Button to bring sidebar back in for larger screens */
.show-sidebar-btn {
    position: fixed;
    top: 20px;
    left: 10px;
    z-index: 1000;
    cursor: pointer;
    display: block;
}

.main-content {
    flex: 1;
    padding: 20px;
    background: #FBFADC;
    backdrop-filter: blur(5px);
    overflow-y: auto;
    height: 100vh;
}

.control-panel {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.control-tile {
    flex: 1;
    padding: 15px;
    /* here is where it changes once i start edditing tiles */
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.30);
}

.custom-calendar {
    width: 100%;
    /* Set the desired width */
    height: 500px;
    /* Set the desired height */
}


.calendar[data-v-2641c2d9]  {
    flex: 1;
    padding: 25px;
    /* here is where it changes once i start edditing tiles */
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.30);
}
.calendar[data-v-2641c2d9] {
    flex: 1;
    padding: 25px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.30);
}


/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .sidebar {
        width: 0;
        overflow: hidden;
        padding: 0%;
        transition: 0.2s;
        flex-direction: column;
        display: flex;
    }


    .menu-icon {
        display: none;
    }

    /* Hide the show-sidebar-btn on small screens when sidebar is visible */
    .show-sidebar-btn {
        display: none;
        color: #00000060;
    }

    .calendar {
        flex: 1;
        padding: 25px;
        /* here is where it changes once i start edditing tiles */
        margin-right: 5px;
        margin-left: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        border-radius: 15px;
        background: rgba(0, 0, 0, 0.30);
    }

}
</style>
